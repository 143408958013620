import { createRouter, createWebHistory } from 'vue-router';
import { User } from 'firebase/auth';
import { routes } from '@/plugins/router/routes';
import { auth } from '@/plugins/firebase';

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
});

router.beforeEach(async (to, from, next) => {
    const skipAuthCheck = to.meta?.skipAuthCheck;
    const currentUser: null | User = await new Promise((resolve, reject) => {
        const unsubscribe = auth.onAuthStateChanged((user) => {
            unsubscribe();
            resolve(user);
        }, reject);
    });

    if (skipAuthCheck) {
        next();
    } else if (!currentUser) {
        next({ name: 'Login' });
    } else {
        const userIdTokenResults = await currentUser.getIdTokenResult();
        const requireRole = to.meta?.requireRole;
        if (userIdTokenResults) {
            if (requireRole) {
                if (
                    typeof userIdTokenResults.claims.roles === 'object' &&
                    (requireRole as string[]).reduce((allow: boolean, role: string) => allow || Object.values(userIdTokenResults.claims?.roles || []).includes(role), false)
                )
                    next();
                else next({ name: 'Login' });
            } else next();
        } else next({ name: 'Login' });
    }
});

export default router;

import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "flex space-x-3" }
const _hoisted_2 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.iconSource)
      ? (_openBlock(), _createElementBlock("img", {
          key: 0,
          class: "h-8 w-8 my-auto",
          src: _ctx.iconSource
        }, null, 8, _hoisted_2))
      : _createCommentVNode("", true),
    _createElementVNode("h1", null, _toDisplayString(_ctx.header), 1)
  ]))
}
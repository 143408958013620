import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "bg-white rounded-lg w-full divide-y" }
const _hoisted_2 = { class: "py-6 mt-4 h-20 px-10 flex relative" }
const _hoisted_3 = { class: "my-auto" }
const _hoisted_4 = { class: "p-10 space-y-10" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("h3", _hoisted_3, _toDisplayString(_ctx.header), 1),
      _renderSlot(_ctx.$slots, "header-action")
    ]),
    _createElementVNode("div", _hoisted_4, [
      _renderSlot(_ctx.$slots, "default")
    ])
  ]))
}
import 'vue-toastification/dist/index.css';
import Toast, { PluginOptions, POSITION, TYPE } from 'vue-toastification';

export const options = {
    toastDefaults: {
        [TYPE.ERROR]: {
            timeout: 10000,
        },
        [TYPE.SUCCESS]: {
            timeout: 3000,
            toastClassName: 'bg-black',
        },
        [TYPE.INFO]: {
            timeout: 5000,
            toastClassName: 'bg-black',
        },
        [TYPE.WARNING]: {
            timeout: 5000,
        },
    },
    position: POSITION.BOTTOM_CENTER,
    timeout: 3000,
} as PluginOptions;

export const toast = Toast;

import { ref, computed, WritableComputedRef } from 'vue';

export default function useDebouncedWindowWidth(debounceDuration: number) {
    const widthTimeout = ref<any>();
    const debouncedWidth = ref<number>(0);

    const windowWidth: WritableComputedRef<number> = computed({
        get(): number {
            return debouncedWidth.value;
        },
        set(newValue: number): void {
            if (widthTimeout.value) clearTimeout(widthTimeout.value);
            widthTimeout.value = setTimeout(() => {
                debouncedWidth.value = newValue;
            }, debounceDuration);
        },
    });

    const resizeHandler = (e?: Event) => {
        windowWidth.value = window.innerWidth;
    };

    return { windowWidth, resizeHandler };
}


import { defineComponent } from 'vue';
import sanitizeHtml from 'sanitize-html';

type HighlightResult = {
    matchLevel: string;
    matchedWords: string[];
    value: string;
};

export default defineComponent({
    props: {
        params: {
            type: Object as () => {
                value: any;
                valueFormatted: any;
                colDef: { [key: string]: any; field: string; valueFormatter?: CallableFunction };
                data: { [key: string]: any; _highlightResult?: { [key: string]: HighlightResult | HighlightResult[] } };
            },
            required: true,
        },
    },
    computed: {
        text() {
            if (this.$props.params.colDef.valueFormatter) {
                return this.$props.params.valueFormatted;
            }
            if (this.$props.params.data._highlightResult && this.$props.params.data._highlightResult[this.$props.params.colDef.field]) {
                const highlightResults = this.$props.params.data._highlightResult[this.$props.params.colDef.field];
                if (Array.isArray(highlightResults)) {
                    return highlightResults.map((currentHighlightResult) => currentHighlightResult.value.replaceAll(/<(\/?)mark>/g, '<$1b>')).join('<br>');
                }

                return (this.$props.params.data._highlightResult[this.$props.params.colDef.field] as HighlightResult).value.replaceAll(/<(\/?)mark>/g, '<$1b>');
            }

            return this.$props.params.value;
        },
    },
    methods: {
        sanitize: sanitizeHtml,
    },
});

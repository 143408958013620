
import { defineComponent } from 'vue';

export default defineComponent({
    props: {
        padding: {
            type: String,
            default: 'py-3',
        },
        textClass: {
            type: String,
            default: 'font-semibold',
        },
        colors: {
            type: String,
            default: 'text-white bg-gray-600',
        },
    },
});

import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AgGridVue = _resolveComponent("AgGridVue")!

  return (_openBlock(), _createElementBlock("div", {
    class: "relative max-w-full",
    style: _normalizeStyle(_ctx.width)
  }, [
    _createElementVNode("div", null, [
      _createVNode(_component_AgGridVue, {
        class: "ag-theme-material",
        "animate-rows": true,
        "row-selection": "single",
        "grid-options": _ctx.gridOptions,
        "column-defs": _ctx.styledHeaders,
        "row-data": _ctx.items,
        onSelectionChanged: _ctx.onSelectionChanged,
        onGridReady: _ctx.onGridReady
      }, null, 8, ["grid-options", "column-defs", "row-data", "onSelectionChanged", "onGridReady"])
    ])
  ], 4))
}
import * as nprogress from 'nprogress';

export default class LoadingIndicator {
    private static activeRequests = 0;

    public static start() {
        if (LoadingIndicator.activeRequests === 0) {
            nprogress.start();
        }
        LoadingIndicator.activeRequests++;
    }

    public static done() {
        LoadingIndicator.activeRequests--;
        if (LoadingIndicator.activeRequests === 0) {
            nprogress.done();
        }
    }
}

import { vModelText as _vModelText, normalizeClass as _normalizeClass, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["readonly"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return _withDirectives((_openBlock(), _createElementBlock("input", {
    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.computedValue) = $event)),
    readonly: _ctx.readOnly,
    class: _normalizeClass([_ctx.padding, "w-full bg-white border border-gray-300 opacity-80 rounded-lg"])
  }, null, 10, _hoisted_1)), [
    [_vModelText, _ctx.computedValue]
  ])
}

import { defineComponent } from 'vue';

export type InfoTableItem = { name: string; value: any; id: string };

export default defineComponent({
    props: {
        items: {
            type: Array as () => { name: string; value: any; id: string }[],
            default: [],
        },
        tableHeaderClass: {
            type: String,
            default: '',
        },
        tableCellClass: {
            type: String,
            default: '',
        },
    },
});

import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, renderSlot as _renderSlot } from "vue"

const _hoisted_1 = { class: "px-4 opacity-80 decoration-clone" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("table", null, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.items, (item, index) => {
      return (_openBlock(), _createElementBlock("tr", {
        key: item.id,
        class: "mt-4"
      }, [
        _createElementVNode("th", {
          class: _normalizeClass([[index < _ctx.items.length - 1 ? 'pb-8' : 'pb-2', _ctx.tableHeaderClass], "text-left pt-0 px-0 opacity-80 pr-6 align-top text-lg font-semibold whitespace-nowrap"])
        }, _toDisplayString(item.name), 3),
        _createElementVNode("td", {
          class: _normalizeClass([[index < _ctx.items.length - 1 ? 'pb-8' : 'pb-2', _ctx.tableCellClass], "pt-0 px-0 relative w-full"])
        }, [
          _renderSlot(_ctx.$slots, `value_${item.id}`, {}, () => [
            _createElementVNode("span", _hoisted_1, _toDisplayString(item.value), 1)
          ])
        ], 2)
      ]))
    }), 128))
  ]))
}

import { defineComponent } from 'vue';

export default defineComponent({
    props: {
        value: {
            type: [String, Number],
            default: '',
        },
        padding: {
            type: String,
            default: 'py-3 px-4',
        },
        readOnly: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        computedValue: {
            get(): number | string {
                return this.$props.value;
            },
            set(newValue: number) {
                this.$emit('update:value', newValue);
            },
        },
    },
});
